import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';

import * as publicApi from './editor/services/public-api';
import { registerGfppEventListeners } from './editor/services/gfpp-event-listener';
import { createAppManifest } from './components/MyAccount/manifest';

let flowAPI: EditorScriptFlowAPI;
let appDefId: string;

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  _,
  _flowAPI,
) => {
  flowAPI = _flowAPI;
  appDefId = appDefinitionId;

  registerGfppEventListeners(editorSDK);
};

const getAppManifest = () =>
  createAppManifest(flowAPI.translations.t as TFunction);

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    refreshApp: () => publicApi.refreshApp(editorSDK),
    setBooleanStyleParamForWidgets: (key: string, value: boolean) => {
      return publicApi.setBooleanStyleParamForWidgets(
        editorSDK,
        appDefId,
        key,
        value,
      );
    },
  },
});

export { editorReady, getAppManifest, _exports as exports };
